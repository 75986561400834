<template>
    <div>
        <div class="tw-flex tw-items-center">
            <FontAwesomeIcon
                :icon="icon.icon"
                class="tw-mr-2.5"
                :class="icon.farbe"
                size="lg"
            />
            <span
                class="tw-font-medium tw-text-xl"
                v-html="props.insight.title"
            />
        </div>
        <div class="tw-ml-8 tw-mt-1">
            <div class="tw-text-hiorg-blau-400 tw-text-sm">
                HiOrg-Server {{ props.insight.lizenz.join(" | ") }}
            </div>
            <div
                v-for="content in props.insight.content"
                class="tw-mt-2"
            >
                <span v-html="content"></span>
            </div>
            <div
                v-for="aktion in props.insight.aktions"
                :key="aktion.url"
                class="tw-pt-1 tw-inline-block"
            >
                <InsightCardAktion
                    :aktion="aktion"
                    text-color="tw-text-hiorg-rot-500"
                />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type {Insight} from "../../models/api/insight/Insight";
import InsightCardAktion from "./InsightCardAktion.vue";
import {iconInsightTypeMapper} from "../../utilities/Insight/IconMapper";

const props = defineProps<{
    insight: Insight;
}>();
const icon = iconInsightTypeMapper(props.insight.type);
</script>
